import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { throttle } from "utils/throttle";
import { useAuthCommonActions } from "hooks";
import { INACTIVITY_TIMEOUT } from "utils/constants";

// События, которые считаются активностью пользователя
const events = [
  "mousemove",
  "mousedown",
  "keydown",
  "click",
  "touchstart",
  "wheel",
  "scroll",
];

// Задержка для throttling
const THROTTLE_DELAY = 2000;
const EXPIRE_TIME_KEY = "expireTime";

const ActivityMonitor = () => {
  const dispatch = useDispatch();
  const { handleLogout } = useAuthCommonActions();

  // Функция для обновления последней активности
  const updateExpiryTime = () => {
    const expireTime = Date.now() + INACTIVITY_TIMEOUT;
    localStorage.setItem(EXPIRE_TIME_KEY, expireTime.toString());
  };

  useEffect(() => {
    const throttledUpdateExpiryTime = throttle(
      updateExpiryTime,
      THROTTLE_DELAY
    );

    // Добавление слушателей событий для отслеживания активности
    events.forEach((event) => {
      window.addEventListener(event, throttledUpdateExpiryTime);
    });

    // Удаляем слушатели при размонтировании
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, throttledUpdateExpiryTime);
      });
    };
  }, [dispatch]);

  useEffect(() => {
    const checkInactivity = () => {
      const expireTimeString = localStorage.getItem(EXPIRE_TIME_KEY);
      const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

      if (expireTime < Date.now()) handleLogout();
    };

    const interval = setInterval(() => {
      checkInactivity();
    }, 10000);

    return () => clearInterval(interval);
  }, [handleLogout]);

  return null;
};

export default ActivityMonitor;
// втавлять в компонент, который появляется лишь когда пользователь активный. в крайнем случае создать обертку для всех активных пайдж компонентов
