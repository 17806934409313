import styled from "@emotion/styled/macro";
import {
  Dropdown,
  DropdownProps,
  DropdownStyled,
  Loader,
  TriggerStyled,
} from "components";
import { FC, useEffect, useRef, useState } from "react";
import { SearchFiltersValues } from "../../History";
import {
  OperationAmount,
  OperationReset,
  OperationType,
  OperationCard,
  // OperationPeriod,
} from "./components";
import theme from "theme";
import { CardDto } from "api/account";
import IconSearch from "atoms/IconSearch/IconSearch";
import { SearchInput } from "components/SearchInput/SearchInput";
import useDebounce from "hooks/useDebounce";

const SearchFiltersStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SearchBarStyled = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 5px;
  & input {
    width: calc(100% - 80px);
  }
`;

const FiltersBarStyled = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: 12px;
  flex-wrap: ${(props) => (props.isMobile ? "nowrap" : "wrap")};
`;

export const DropdownAnchor = styled(Dropdown)<
  DropdownProps & { isCard?: boolean }
>`
  background: ${(props) =>
    props.isDropped ? "var(--main-color-bg-widgets)" : theme.palette.gray.b50};
  background-color: ${(props) =>
    props.clear
      ? "var(--brand-color-disabled)"
      : "var(--main-color-bg-widgets)"};
  padding: 12px 12px 12px 16px;
  border-radius: 12px;
  display: flex;
  ${TriggerStyled} {
    color: ${(props) =>
      props.isDropped
        ? "var(--main-color-notification-description)"
        : "var(--main-color-text-title)"};
    & > svg > path {
      stroke: ${(props) =>
        props.isDropped
          ? theme.palette.blue.b400
          : "var(--main-color-text-title)"};
      fill: ${(props) =>
        props.isDropped
          ? theme.palette.blue.b400
          : "var(--main-color-text-title)"};
    }
  }
  ${DropdownStyled} {
    width: ${(props) => (props.isCard ? "342px" : "312px")};
    top: calc(100% + 14px);
    left: 0;
    box-shadow: 18px 19px 64.8px 0px var(--main-color-box-shadow-5);
    ${(props) =>
      props.isCard
        ? `
      padding: 8px 0;
      gap: 0;
      border-radius: 13px;
      `
        : ""}
  }
`;

export const AnchorStyled = styled.span`
  color: var(--brand-color-primary);
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OperationSearch = styled.div`
  background: ${theme.palette.gray.b50};
  padding: 10px 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
`;

interface SearchFiltersProps {
  filterValues: SearchFiltersValues;
  cards: CardDto[];
  isMobile: boolean;
  isChanged: boolean;
  onFilterChange: (value: Partial<SearchFiltersValues>) => void;
  onSearch: () => void;
  onClearAmount: () => void;
  onClearCard: () => void;
  onChangeCard: (cardId: string) => void;
  onReset: (fullReset: boolean) => void;
  isLoading: boolean;
  isSearchVisible: boolean;
  setSearchVisible: (values: boolean) => void;
}

export const SearchFilters: FC<SearchFiltersProps> = ({
  filterValues,
  isMobile,
  cards,
  isChanged,
  onFilterChange,
  onSearch,
  onClearAmount,
  onClearCard,
  onChangeCard,
  onReset,
  isLoading,
  isSearchVisible,
  setSearchVisible,
}) => {
  const { search, operationFilterType, fromAmount, toAmount, cardId } =
    filterValues;
  const floatRef = useRef<HTMLDivElement>(null);
  const [floatLeft, setFloatLeft] = useState<number>(0);

  const [localSearch, setLocalSearch] = useState(filterValues.search || "");
  const debouncedSearch = useDebounce<string>(localSearch, 1000);

  // Обработка изменений дебаунсированного значения поисковой строки
  useEffect(() => {
    if (debouncedSearch !== filterValues.search) {
      onFilterChange({ ...filterValues, search: debouncedSearch });
    }
  }, [debouncedSearch, filterValues, onFilterChange]);

  const handleReset = () => {
    onReset(true);

    setSearchVisible(false);
    setFloatLeft(0);
  };

  let rightNode: null | React.ReactNode = null;
  if (search) {
    rightNode = <IconSearch color="var(--brand-color-primary)" />;
  }
  if (search && isLoading) {
    rightNode = <Loader size={18} />;
  }

  return (
    <SearchFiltersStyled>
      {!isMobile ? (
        <SearchBarStyled>
          <SearchInput
            onChange={(event) => {
              setLocalSearch(event.target.value);
            }}
            value={localSearch}
            placeholder="Поиск по названию"
          />
          {!isMobile && (
            <OperationType
              onChange={onFilterChange}
              value={operationFilterType}
            />
          )}
        </SearchBarStyled>
      ) : (
        <div />
      )}
      <FiltersBarStyled
        ref={floatRef}
        isMobile={isMobile}
        style={{
          left: floatLeft,
          overflow: isMobile ? "scroll" : "visible",
        }}
      >
        {isMobile && (
          <OperationSearch onClick={() => setSearchVisible(!isSearchVisible)}>
            <IconSearch />
          </OperationSearch>
        )}

        <OperationAmount
          value={{ fromAmount, toAmount }}
          onChange={onFilterChange}
          onSearch={onSearch}
          onClear={onClearAmount}
          isMobile={isMobile}
        />

        {cards.length && (
          <OperationCard
            cards={cards}
            value={cardId}
            onChangeCard={onChangeCard}
            onClear={onClearCard}
            isMobile={isMobile}
          />
        )}

        {isMobile && (
          <OperationType
            onChange={onFilterChange}
            value={operationFilterType}
          />
        )}
        <OperationReset onReset={handleReset} isChanged={isChanged} />
      </FiltersBarStyled>

      {isMobile && isSearchVisible ? (
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
          }}
        >
          <SearchInput
            value={localSearch}
            onChange={(event) => setLocalSearch(event.target.value)}
            placeholder="Поиск по названию"
          />
        </div>
      ) : (
        <div />
      )}
    </SearchFiltersStyled>
  );
};
