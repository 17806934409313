import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import * as d3 from "d3";

import "./HistoryChart.css";

import theme from "theme";
import { Stack, Typography } from "@mui/material";
import { getExpenseSum, getIncomeSum, shortId } from "./HistoryChart.utils";
import {
  ChartGroupKey,
  DataElement,
  GroupColor,
  GroupOpacityColor,
  HistoryChartProps,
} from "./HistoryChart.types";
import {
  BAR_COLORS_ORDERED,
  BAR_HEIGHT,
  BAR_WIDTH,
  CHART_GROUP_KEYS,
  DEFAULT_WIDTH,
} from "./HistoryChart.consts";
import { SkeletonContainer } from "components";
import moment from "moment";
import { RU_LOCALE } from "constants/chart";

d3.timeFormatDefaultLocale(RU_LOCALE);

export const HistoryChart: React.FC<HistoryChartProps> = ({
  data,
  expenseSum,
  incomeSum,
  chartType,
  isLoading,
  containerRef,
  isMobile,
  defaultWidth = DEFAULT_WIDTH,
  barWidth = BAR_WIDTH,
  barHeight = BAR_HEIGHT,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(defaultWidth);

  const id = shortId();
  const isMoreThanMonth =
    moment(new Date(data[0].date!)).diff(
      moment(new Date(data[data.length - 1].date!)),
      "month"
    ) !== 0;

  const margin = {
    top: isMobile ? 5 : 10,
    right: isMobile ? 16 : 20,
    bottom: 16,
    left: isMobile ? 16 : 20,
  };
  const height = barHeight;

  const stack = d3
    .stack()
    .keys(CHART_GROUP_KEYS)
    .order(d3.stackOrderNone)
    .offset(d3.stackOffsetNone);
  // @ts-expect-error property date is not number type
  const series = stack(data);

  const xScale = d3.scaleUtc(
    [new Date(data[0].date!), new Date(data[data.length - 1].date!)],
    [10, width - 15]
  );
  const yScale = d3.scaleLinear().rangeRound([barHeight, 0]).domain([0, 100]);

  const xAxisArea = useCallback(
    (ref: SVGGElement) => {
      const xAxis = d3.axisBottom(xScale);
      xAxis.tickFormat((d) => d3.timeFormat("%d")(d as Date));
      xAxis.ticks(data.length);

      if (data.length < 4) {
        xAxis.tickValues(data.map((el) => new Date(el.date!)));
      }

      const xAxisSelected = d3.select(ref);

      xAxisSelected.call(xAxis);
      xAxisSelected.selectAll(".domain").remove().exit();
      xAxisSelected.selectAll(".tick").selectAll("line").remove();
      xAxisSelected
        .selectAll("text")
        .attr("fill", theme.primary.gray?.[900] || "#454A3F")
        .attr("font-size", isMobile ? 9 : 11)
        .attr("font-weight", 500);

      xAxisSelected;
    },
    [data, xScale, isMobile]
  );

  const xAxisAreaMonths = useCallback(
    (ref: SVGGElement) => {
      const xAxis = d3.axisBottom(xScale);
      xAxis.tickFormat((d) => d3.timeFormat("%B")(d as Date));

      xAxis.ticks(data.length / 30);
      xAxis.tickSize(3).offset(16);

      const xAxisSelected = d3.select(ref);

      xAxisSelected.call(xAxis);
      xAxisSelected.selectAll(".domain").remove().exit();
      xAxisSelected.selectAll(".tick").selectAll("line").remove();
      xAxisSelected
        .selectAll("text")
        .attr("fill", theme.primary.gray?.[900] || "#454A3F")
        .attr("font-size", isMobile ? 9 : 11)
        .attr("font-weight", 500);

      xAxisSelected;
    },
    [data, xScale, isMobile]
  );

  d3.selectAll("body").selectAll(`.${id}__chart-tooltip`).remove().exit();

  d3.selectAll(`body`)
    .append("div")
    .attr("class", `${id}__chart-tooltip history-chart__tooltip`)
    .style("position", "absolute")
    .style("min-width", "120px")
    .style("z-index", "100000")
    .style("visibility", "hidden")
    .style("background-color", "white")
    .style("border-radius", "8px")
    .style("padding", "4px 12px")
    .style("transform", "translate(0, -50%)");

  const getGroupColor = d3
    .scaleOrdinal<string>()
    .domain(CHART_GROUP_KEYS)
    .range([GroupColor.Orange, GroupColor.Green, GroupColor.White]);
  const getGroupOpacityColor = d3
    .scaleOrdinal<string>()
    .domain(CHART_GROUP_KEYS)
    .range([
      GroupOpacityColor.Orange,
      GroupOpacityColor.Green,
      GroupOpacityColor.White,
    ]);

  const onMouseOver = useCallback(
    (e: unknown, d: DataElement) => {
      if (isLoading) return;

      CHART_GROUP_KEYS.forEach((key) => {
        d3.selectAll(`.${key}`)
          .transition()
          .attr("fill", getGroupOpacityColor(key));
      });

      d3.select(`.${id}__chart-expenseSum`)
        .transition()
        .delay(300)
        .text(getExpenseSum(d.data.expenseSum || 0));
      d3.select(`.${id}__chart-incomeSum`)
        .transition()
        .delay(300)
        .text(getIncomeSum(d.data.incomeSum || 0));

      d3.selectAll(`.${id}__${d.data.date}`)
        .nodes()
        .forEach((node, index) => {
          if (node) {
            d3.select(node)
              .transition()
              .attr("fill", BAR_COLORS_ORDERED[index])
              .attr(
                "filter",
                "drop-shadow(0px 4px 11.7px 0px rgba(100, 115, 95, 0.3))"
              );
          }
        });

      d3.select(`.${id}__chart-tooltip`)
        .transition()
        .style("visibility", "visible");
    },
    [isLoading, getGroupOpacityColor, id]
  );

  const onMouseMove = useCallback(
    (
      e: MouseEvent,
      d: d3.SeriesPoint<{
        [key: string]: number;
      }>
    ) => {
      const tooltip = d3.select(`.${id}__chart-tooltip`);
      const tooltipWidth = (tooltip.node() as Element)?.getBoundingClientRect()
        .width;
      let tooltipPosX = e.pageX;

      tooltip.html(
        `<span class="tooltip__text">- ${
          (d.data.expenseSum || 0) * -1
        } ₽ /</span> <span class="tooltip__text tooltip__text_primary"> + ${
          d.data.incomeSum || 0
        } ₽</span>`
      );

      if (e.pageX + tooltipWidth > window.innerWidth) {
        tooltipPosX -= tooltipWidth;
      }

      return tooltip
        .style("top", e.pageY - 30 + "px")
        .style("left", tooltipPosX + "px");
    },
    [id]
  );

  const onMouseOut = useCallback(
    (e: unknown, d: DataElement) => {
      CHART_GROUP_KEYS.forEach((key) => {
        d3.selectAll(`.${key}`).transition().attr("fill", getGroupColor(key));
      });

      d3.selectAll("." + `${id}__${d.data.date}`)
        .transition()
        .delay(200)
        .attr("fill", "");

      d3.select(`.${id}__chart-expenseSum`)
        .transition()
        .delay(500)
        .text(getExpenseSum(expenseSum));
      d3.select(`.${id}__chart-incomeSum`)
        .transition()
        .delay(500)
        .text(getIncomeSum(incomeSum));

      return d3
        .select(`.${id}__chart-tooltip`)
        .transition()
        .style("visibility", "hidden");
    },
    [expenseSum, incomeSum, getGroupColor, id]
  );

  const onScroll = useCallback(
    (e: WheelEvent) => {
      if (!ref.current) return;

      if (ref.current.getBoundingClientRect().width === width) return;

      e.preventDefault();

      if (e.deltaY > 0) ref.current.scrollLeft += 100;
      else ref.current.scrollLeft -= 100;
    },
    [width]
  );

  useEffect(() => {
    const wrapper = containerRef.current;

    if (!wrapper) return;

    wrapper.addEventListener("wheel", onScroll, {
      passive: false,
    });

    return () => wrapper?.removeEventListener("wheel", onScroll);
  }, [containerRef, onScroll]);

  useLayoutEffect(() => {
    const group = d3
      .select(`.${id}__chart-container`)
      .selectAll(`.${id}__chart-group`)
      .remove()
      .exit()
      .data(series)
      .enter()
      .append("g")
      .attr("class", (d) => `${id}__chart-group ${d.key}`)
      .sort((d) => {
        if (d.key === ChartGroupKey.RemainValue) return -1;
        if (d.key === ChartGroupKey.IncomeValue) return -1;

        return 1;
      });

    group
      .transition()
      .attr("fill", (d) => {
        if (d.key === "incomeValue" || d.key === "expenseValue")
          return "#E8ECE3";

        return getGroupColor(d.key);
      })
      .on("end", () => {
        group
          .transition()
          .duration(500)
          .attr("fill", (d) => getGroupColor(d.key));
      });

    const rects = group
      .selectAll("rect")
      .data(function (d) {
        return d;
      })
      .enter()
      .append("rect");

    d3.selectAll(`.${ChartGroupKey.RemainValue}`)
      .selectAll("rect")
      .data((d: any) => {
        return d;
      })
      .attr("y", function (d) {
        return yScale(100);
      })
      .attr("height", barHeight);

    d3.selectAll(`.${ChartGroupKey.IncomeValue}`)
      .selectAll("rect")
      .data((d: any) => {
        return d;
      })
      .attr("y", function (d) {
        return yScale(0);
      })
      .attr("height", 0)
      .transition()
      .duration(500)
      .attr("y", function (d: any) {
        return yScale(d.data.incomeValue);
      })
      .attr("height", (d: any) => {
        return barHeight - yScale(d.data.incomeValue);
      });

    d3.selectAll(`.${ChartGroupKey.ExpenseValue}`)
      .selectAll("rect")
      .data((d: any) => {
        return d;
      })
      .attr("y", function (d) {
        return yScale(0);
      })
      .attr("height", 0)
      .transition()
      .duration(500)
      .attr("y", function (d: any) {
        return yScale(d.data.expenseValue);
      })
      .attr("height", (d: any) => {
        return barHeight - yScale(d.data.expenseValue);
      });

    rects
      .attr("x", (d) => xScale(new Date(d.data.date)) - barWidth / 2)
      .attr("width", barWidth)
      .attr("rx", (d) => {
        const isRemainGroup = d[1] >= 100;

        if (isRemainGroup) return 4;

        return 3;
      })
      .attr("class", function (d) {
        return `${id}__${d.data.date}`.replace(/\s/g, "") + " group";
      })
      .on("mouseover", onMouseOver)
      .on("mousemove ", onMouseMove)
      .on("mouseout", onMouseOut);
  }, [
    getGroupColor,
    id,
    xScale,
    yScale,
    series,
    barHeight,
    barWidth,
    onMouseOver,
    onMouseMove,
    onMouseOut,
  ]);

  useLayoutEffect(() => {
    const calculateSize = (ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current) {
        const size = ref.current.getBoundingClientRect();
        const newWidth = size.width - margin.left - margin.right;

        setWidth(Math.max(data.length * (barWidth + 9.3), newWidth));
      }
    };

    const handleResize = () => {
      calculateSize(containerRef);
    };

    calculateSize(containerRef);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef, data, barWidth, margin.left, margin.right]);

  return (
    <div className="history-chart">
      <Stack
        direction="row"
        sx={{
          marginBottom: 12,
        }}
      >
        <SkeletonContainer isLoading={isLoading} height={32} width="100%">
          {chartType !== "income" && (
            <Typography
              color="gray.b900"
              variant="h5"
              fontWeight={500}
              className={`${id}__chart-expenseSum`}
            >
              {getExpenseSum(expenseSum)}
            </Typography>
          )}

          {!chartType && (
            <Typography
              color="gray.b900"
              variant="h5"
              fontWeight={500}
              sx={{
                margin: "0 5px",
              }}
            >{` / `}</Typography>
          )}

          {chartType !== "expense" && (
            <Typography
              color="#739B67"
              variant="h5"
              fontWeight={500}
              className={`${id}__chart-incomeSum`}
            >
              {getIncomeSum(incomeSum)}
            </Typography>
          )}
        </SkeletonContainer>
      </Stack>

      <div style={{ position: "relative" }}>
        <div ref={ref} style={{ width: "100%", overflow: "hidden" }}>
          <svg
            height={
              height + margin.top + margin.bottom + (isMoreThanMonth ? 15 : 0)
            }
            width={width}
          >
            <g
              ref={xAxisArea}
              style={{ transform: `translate(0, ${height}px)` }}
            />

            {isMoreThanMonth && (
              <g
                ref={xAxisAreaMonths}
                width={width}
                style={{
                  transform: `translate(0px, ${height + 20}px)`,
                }}
              />
            )}
            <g className={`${id}__chart-container`} />
          </svg>
        </div>
      </div>
    </div>
  );
};
