import { ChartGroupKey, GroupColor } from "./HistoryChart.types";

export const BAR_WIDTH = 16;
export const MOBILE_BAR_WIDTH = 12;
export const BAR_HEIGHT = 134;
export const MOBILE_BAR_HEIGHT = 77;

export const CHART_GROUP_KEYS = [
  ChartGroupKey.ExpenseValue,
  ChartGroupKey.IncomeValue,
  ChartGroupKey.RemainValue,
];

export const BAR_COLORS_ORDERED = [
  GroupColor.White,
  GroupColor.Green,
  GroupColor.Orange,
];

export const DEFAULT_WIDTH = 788;
