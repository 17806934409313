import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum MobileTabVariants {
  main = "main",
  transfersHub = "transfersHub",
  history = "history",
  chat = "chat",
  other = "other",
}

export const mobileTabLabels: Record<MobileTabVariants, string> = {
  [MobileTabVariants.main]: "Главная",
  [MobileTabVariants.transfersHub]: "Переводы",
  [MobileTabVariants.history]: "История",
  [MobileTabVariants.chat]: "Чат",
  [MobileTabVariants.other]: "Ещё",
};

export enum TabVariants {
  main = "main",
  transfersHub = "transfersHub",
  history = "history",
  other = "other",
}

export enum AnotherPages {
  promotion = "promotion",
  transfers = "transfers",
  contacts = "contacts",
  exchangeRates = "exchangeRates",
}

export const tabLabels: Record<TabVariants, string> = {
  [TabVariants.main]: "Главная",
  [TabVariants.transfersHub]: "Переводы",
  [TabVariants.history]: "История",
  [TabVariants.other]: "Еще",
};

const allTabVariants = {
  ...MobileTabVariants,
  ...TabVariants,
} as const;
export type AllTabVariants = MobileTabVariants | TabVariants | AnotherPages;

export enum HeightsVariants {
  "100vh" = "100vh",
  none = "none",
}

export interface ITransferState {
  pan?: string;
  amount?: number;
  reason?: string;
  cardId?: string;
}

export interface ICardState {
  titleShort?: string;
  descriptionShort?: string;
  srcImgLenta?: string;
  srcImgDetail?: string;
  id?: number;
  category?: string;
}

export type ActiveTabState = ITransferState;

export type SystemState = {
  activeTab: AllTabVariants;
  previousTab: AllTabVariants;
  needToScrollToTabs: boolean;
  bodyMaxHeight: HeightsVariants;
  activeTabState: ActiveTabState;
  chatIsOpen: boolean;
  isNewAndNotificationsOpen: boolean;
};

const initialState: SystemState = {
  activeTab: "main" as AllTabVariants,
  needToScrollToTabs: false,
  bodyMaxHeight: "none" as HeightsVariants,
  activeTabState: {},
  previousTab: "main" as AllTabVariants,
  chatIsOpen: false,
  isNewAndNotificationsOpen: false,
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
    setActiveTab: (
      state,
      {
        payload,
      }: PayloadAction<{
        tab: AllTabVariants;
        state?: ActiveTabState;
      }>
    ) => {
      state.previousTab = state.activeTab;
      state.activeTab = payload.tab;
      state.activeTabState = payload.state || {};
    },
    setBack: (state) => {
      state.activeTab = state.previousTab;
    },
    setChatOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.chatIsOpen = payload;
    },
    setNewAndNotificationsOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isNewAndNotificationsOpen = payload;
    },

    setInitialTab: (
      state,
      {
        payload,
      }: PayloadAction<{
        isMobile?: boolean;
      }>
    ) => {
      state.activeTab = payload.isMobile
        ? MobileTabVariants.main
        : TabVariants.main;
    },
    setNeedScrollState: (state, { payload }: PayloadAction<boolean>) => {
      state.needToScrollToTabs = payload;
    },
    setBodyMaxHeight: (state, { payload }: PayloadAction<HeightsVariants>) => {
      state.bodyMaxHeight = payload;
    },
  },
  extraReducers: {},
});

export default systemSlice;
