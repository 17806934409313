import { Modal } from "molecules/Modal/Modal";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { useCounter } from "hooks";
import { getSecondsPluralForms } from "utils";
import { useState } from "react";
import { retryOneTimePassword } from "store/slices/auth";
import { getCardCvvInfo } from "store/slices/cardsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAppThunkDispatch } from "store";
import { CardCvvDto } from "api/account";
import { useIsMobile } from "hooks/useIsMobile";
import { CodeInput } from "components/CodeInput";
import { Button } from "components/Button";

type Props = {
  isOpen: boolean;
  verifyCvvToken: string;
  setCvv: (val: string | undefined) => void;
  setCvvModalVisible: (val: boolean) => void;
  setRequisitesCard: (val: object) => void;
};

export const CvvModal = ({
  isOpen,
  verifyCvvToken,
  setCvv,
  setCvvModalVisible,
}: Props) => {
  const [code, setCode] = useState<string>("");
  const { count, restartCounter } = useCounter();
  const secondsWord = getSecondsPluralForms(count);
  const theme = useTheme();
  const dispatch = useDispatch();
  const dispatchApp = useAppThunkDispatch();
  const {
    cards: { isLoading, error, mainCard },
    profile: { user },
  } = useSelector((state: any) => state);
  const phoneLastNum = `${(user?.phone || "").slice(-4, -2)} ${(
    user?.phone || ""
  ).slice(-2)}`;
  const { isMobile } = useIsMobile();

  const handleConfirm = () => {
    dispatchApp(
      getCardCvvInfo({
        cardUID: mainCard?.cardId,
        verifyToken: verifyCvvToken,
        verifyCode: code,
      })
    )
      .unwrap()
      .then((res: CardCvvDto) => {
        setCvvModalVisible(false);
        setCvv(res.cvv);
        setCode("");
      })
      .catch((err) => {
        return err;
      });
  };

  const handleResendCode = () => {
    dispatch(retryOneTimePassword(verifyCvvToken));
    restartCounter();
  };

  const SMSActions = (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        mb={64}
      >
        <Typography sx={{ textAlign: "center" }}>Код из SMS</Typography>
        <CodeInput
          length={4}
          setCode={setCode}
          error={Boolean("")}
          clearError={() => {}}
        />
        {/* {errorObj.title && (
          <Typography
            sx={{
              margin: 0,
              color: "var(--error-color-icon)",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            {errorObj.title}
          </Typography>
        )} */}
      </Box>

      <Box>
        <Box display="flex" flexDirection="column" rowGap={count > 0 ? 20 : 12}>
          <Button
            onClick={handleConfirm}
            variant="primary"
            disabled={code.length < 4}
            isLoading={isLoading}
            title="Продолжить"
          />
          {count === 0 ? (
            <Button
              onClick={handleResendCode}
              variant="text"
              title="Отправить код повторно"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
          ) : (
            <Box textAlign="center">
              <Typography variant="text_5" color="gray.b400">
                Вы сможете отправить код повторно <br /> через{" "}
                <Typography variant="text_5" color="blue.b400">
                  {count} {secondsWord}.
                </Typography>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );

  const onClose = () => setCvvModalVisible(false);

  return (
    <Modal
      title="Картa"
      isOpen={isOpen}
      buttonVisible
      isProfile
      callback={onClose}
      onClose={onClose}
    >
      <Box mx={32}>
        <Box display="flex" flexDirection="column">
          <Typography
            sx={{
              color: "rgba(69, 74, 63, 0.90)",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            Введите код из сообщения, чтобы посмотреть <br /> cvv2-код карты
          </Typography>
        </Box>

        <Box
          component="form"
          display="flex"
          flexDirection="column"
          mt={20}
          autoComplete="off"
        >
          {SMSActions}
        </Box>
      </Box>
    </Modal>
  );
};
