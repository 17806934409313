import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { HistoryModal } from "./HistoryModal";
import { useDispatch, useSelector } from "react-redux";
import {
  HISTORY_TABS,
  STATEMENT_TYPE_TO_TABS,
  appendExpenseList,
  appendHistoryList,
  appendIncomeList,
  getCategoryExpense,
  getCategoryIncome,
  getExpenseList,
  getHistoryChartData,
  getHistoryList,
  getIncomeList,
  getListReport,
  setActiveCategory,
  setActiveTab,
  setDate,
} from "store/slices/historySlice";
import { SkeletonContainer } from "components";
import { InfiniteScroll } from "widgets/ChatWidget/components";
import {
  GetListRequestOperationFilterTypeEnum,
  StatementType,
  StatementView,
} from "api/account";
import { SearchFilters } from "./components";
import styled from "@emotion/styled/macro";
import theme from "theme";
import { parseDate } from "utils";
import { useIsMobile } from "hooks/useIsMobile";
import { HistoryTabs } from "./components/HistoryTabs";
import { FinancialAnalysisWidget } from "./components/FinancialAnalysisWidget/FinancialAnalysisWidget";
import { FileType, downloadFile } from "utils/downloadFile";
import { EmptyScreenDefault } from "./components/EmptyScreenDefault/EmptyScreenDefault";
import { EmptyScreenCustom } from "./components/EmptyScreenCustom/EmptyScreenCustom";
import { getEndOfDay } from "utils/getEndOfDay";
import {
  OperationDate,
  ValueDateType,
} from "./components/SearchFilters/components";
import { OperationItem } from "./components/OperationItem";
import { parseTransactionsDate } from "utils/parseTransactionsDate";
import { HistoryChart } from "molecules/HistoryChart/HistoryChart";
import { Stack } from "@mui/system";
import { HistoryChartTabs } from "molecules/HistoryChartTabs/HistoryChartTabs";
import { HistoryChartTabsKeys } from "constants/chart";
import {
  generatePlaceholderData,
  getChartBarHeight,
  getChartBarWidth,
} from "molecules/HistoryChart/HistoryChart.utils";
import moment from "moment";

export const InStatementTypes: StatementType[] = [
  StatementType.CashIn,
  StatementType.OtherIn,
  StatementType.CashInExternal,
  StatementType.Salary,
  StatementType.TransferIn,
  StatementType.CashBack,
];

export type SearchFiltersValues = {
  search: string;
  fromAmount: number | null;
  toAmount: number | null;
  from: string;
  to: string;
  operationFilterType: GetListRequestOperationFilterTypeEnum | "";
  page: number;
  cardId: string;
  accId?: string;
  categoryIds?: Array<number>;
};

export const EmptyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  h2 {
    font-size: 18px;
    line-height: 24px;
    color: var(--main-color-text-secondary);
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
    margin-top: 20px;
  }
  h4 {
    color: var(--main-color-text-title);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin: 0px;
    margin-top: 10px;
  }
`;

export const DateStyled = styled.div`
  background: var(--main-color-white);
  width: calc(100% - 20px);
  padding: 0px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-color-primary);
`;

export const AmountStyled = styled.span<{ isIncoming: boolean }>`
  color: ${(props) =>
    props.isIncoming ? theme.palette.blue.b400 : theme.palette.gray.b800};
  margin-left: auto;
  white-space: nowrap;
  &:before {
    content: "${(props) => (props.isIncoming ? "+" : "")}";
  }
`;

const HistoryWrapper = styled.div<{
  isMobile: boolean;
  isEmptyScreen: boolean;
}>`
  overflow-x: ${(props) => (props.isMobile ? "hidden" : "inherit")};
  overflow-y: visible;
  height: ${(props) => (props.isEmptyScreen ? "calc(100svh - 250px)" : "100%")};
  margin-top: ${(props) => (props.isMobile ? "24px" : "32px")};
`;

const DashboardWrapper = styled.div`
  padding: 12px 20px 8px 20px;
  background-color: ${theme.primary.gray?.[200]};
  border-radius: 16px;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    padding: 12px 16px 8px 16px;
    margin-bottom: 8px;
  }
`;

const History = () => {
  const dispatch = useDispatch();
  const {
    history: {
      historyList,
      isLoading: isApiLoading,
      activeTab,
      expenseList,
      incomeList,
      isReportLoading,
      activeCategoryId,
      widgetData,
      historyChartData,
      isHistoryChartDataLoading,
      showMobileFinancialAnalysisPage,
      date: { from, to },
    },
    cards,
    system: { activeTabState },
  } = useSelector((state: any) => state);

  const data = {
    [HISTORY_TABS.ALL_OPERATIONS]: historyList,
    [HISTORY_TABS.EXPENSES]: expenseList,
    [HISTORY_TABS.INCOMES]: incomeList,
  };

  const [isSearchVisible, setSearchVisible] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    historyId: "",
  });
  const { isMobile } = useIsMobile();
  const lastBlockRef = useRef<HTMLDivElement>(null);
  const rootSentinelRef = useRef<HTMLDivElement>(null);
  const dashboardRef = useRef<HTMLDivElement>(null);

  const dateNow = new Date();
  const fromDate = isMobile
    ? moment().add(1, "day").subtract(2, "week")
    : moment().utc().startOf("month");

  const initialPayload = {
    size: 10,
  };

  const initialState = {
    search: "",
    fromAmount: null,
    toAmount: null,
    from: fromDate.toISOString(),
    to,
    operationFilterType: "" as GetListRequestOperationFilterTypeEnum | "",
    page: 0,
    cardId: activeTabState.cardId || "",
    acctId: cards.account.accountNumber,
    categoryIds: undefined,
  };

  const [searchFilters, setSearchFilters] =
    useState<SearchFiltersValues>(initialState);
  const [chartTab, setChartTab] = useState<HistoryChartTabsKeys>(
    HistoryChartTabsKeys.Days
  );

  const payload = {
    ...initialPayload,
    ...searchFilters,
    ...{
      operationFilterType: searchFilters.operationFilterType || null,
    },
  };

  const fromTextFilter = parseDate(payload.from);
  const toTextFilter = parseDate(payload.to);
  const fromTextDefault = parseDate(fromDate.toISOString());
  const toTextDefault = parseDate(dateNow.toISOString());

  const isDefaultFilters =
    !payload.search.length &&
    !payload.cardId.length &&
    !payload.fromAmount &&
    !payload.toAmount &&
    fromTextFilter === fromTextDefault &&
    toTextFilter === toTextDefault;

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isChanged, setChanged] = useState<boolean>(false);

  const onChangeChartTab = (e: SyntheticEvent, value: HistoryChartTabsKeys) => {
    setChartTab(value);
  };

  const getChartTypeByActiveTab = () => {
    if (activeTab === HISTORY_TABS.EXPENSES) return "expense";
    if (activeTab === HISTORY_TABS.INCOMES) return "income";
  };

  const openModal = (id: string) =>
    setModalStatus(() => ({ historyId: id, isOpen: true }));

  const closeModal = () =>
    setModalStatus(() => ({ historyId: "", isOpen: false }));

  const handleLoadMore = () => {
    const len = data[activeTab].reduce(
      (acc, it) => it.statements.length + acc,
      0
    );
    const isEndOfSlice = len % 10 === 0;
    const isSamePage = Math.floor(len / 10) === searchFilters.page;

    if (!isSamePage && isEndOfSlice) {
      setSearchFilters({
        ...searchFilters,
        page: Math.floor(len / 10),
      });
    }
  };

  const checkChanged = (newDate: ValueDateType) => {
    const isValuesChanged =
      JSON.stringify({
        ...initialState,
        from: "",
        to: "",
      }) !==
      JSON.stringify({
        ...searchFilters,
        from: "",
        to: "",
      });

    let isDateChanged = false;
    if (
      newDate.from !== fromDate.toISOString() ||
      newDate.to !== getEndOfDay(dateNow)
    ) {
      isDateChanged = true;
    }

    setChanged(isValuesChanged || isDateChanged);
  };

  const handleSearch = () => {
    setLoading(true);
    setSearchFilters({ ...searchFilters, page: 0 });
    checkChanged({ from: searchFilters.from, to: searchFilters.to });
  };

  const handleClearAmount = () => {
    setSearchFilters({
      ...searchFilters,
      fromAmount: null,
      toAmount: null,
      page: 0,
    });
    setLoading(true);
    setChanged(false);
  };

  const handleClearCard = () => {
    setSearchFilters({
      ...searchFilters,
      cardId: "",
      page: 0,
    });
    setLoading(true);
    setChanged(false);
  };

  const handleChangeCard = (cardId: string) => {
    setSearchFilters({
      ...searchFilters,
      cardId,
      page: 0,
    });
    setLoading(true);
    setChanged(false);
  };

  const handleReset = (fullReset: boolean) => {
    if (fullReset) {
      if (activeTab === HISTORY_TABS.ALL_OPERATIONS && isMobile) {
        dispatch(
          // @ts-ignore
          getCategoryExpense({
            ...initialState,
            from: fromDate.toISOString(),
            to: getEndOfDay(dateNow),
          })
        );
      }
      dispatch(setActiveCategory(null));
      dispatch(
        setDate({
          from: fromDate.toISOString(),
          to: getEndOfDay(dateNow),
        })
      );
    }

    setSearchFilters(
      fullReset
        ? {
            ...initialState,
            from: fromDate.toISOString(),
            to: getEndOfDay(dateNow),
          }
        : { ...searchFilters, search: "", page: 0 }
    );
    setLoading(true);
    setChanged(false);
  };

  const handleDownload = () => {
    if (isReportLoading) return;
    const operationViewType = {
      [HISTORY_TABS.ALL_OPERATIONS]: StatementView.All,
      [HISTORY_TABS.EXPENSES]: StatementView.Expense,
      [HISTORY_TABS.INCOMES]: StatementView.Income,
    };
    dispatch(
      // @ts-ignore
      getListReport({
        ...payload,
        operationViewType: operationViewType[activeTab],
      })
    )
      // @ts-ignore
      .unwrap()
      .then((res) => downloadFile(res, "report.xls", FileType.EXCEL));
  };

  const changeDateArrowHandler = (
    { from, to }: ValueDateType,
    initCategory?: boolean
  ) => {
    checkChanged({ from, to });

    const newPayload = {
      ...payload,
      page: 0,
      from,
      to,
    };

    const oldPayload = {
      ...initialState,
      from,
      to,
    };

    dispatch(
      getHistoryChartData({
        ...newPayload,
        statementView: STATEMENT_TYPE_TO_TABS[activeTab],
      })
    );

    if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
      // @ts-ignore
      dispatch(getHistoryList(newPayload));
      if (isMobile) {
        dispatch(
          // @ts-ignore
          getCategoryExpense(initCategory ? oldPayload : newPayload)
        );
      }
    }
    if (activeTab === HISTORY_TABS.EXPENSES) {
      // @ts-ignore
      dispatch(getExpenseList(newPayload));
      // @ts-ignore
      dispatch(getCategoryExpense(newPayload));
    }
    if (activeTab === HISTORY_TABS.INCOMES) {
      // @ts-ignore
      dispatch(getIncomeList(newPayload));
      // @ts-ignore
      dispatch(getCategoryIncome(newPayload));
    }
  };

  const reset = () => {
    // @ts-ignore
    setSearchFilters((prev) => ({ ...prev, operationFilterType: "", page: 0 }));
  };

  const onChangeFilterValue = (filterUpdate: Partial<SearchFiltersValues>) => {
    setSearchFilters({ ...searchFilters, ...filterUpdate });
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters.search.length, searchFilters.operationFilterType]);

  useEffect(() => {
    if (isLoading) {
      dispatch(
        getHistoryChartData({
          ...payload,
          statementView: STATEMENT_TYPE_TO_TABS[activeTab],
        })
      );

      if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
        // @ts-ignore
        dispatch(getHistoryList(payload));
        // @ts-ignore
      }
      if (activeTab === HISTORY_TABS.EXPENSES) {
        // @ts-ignore
        dispatch(getExpenseList(payload));
        // @ts-ignore
        dispatch(getCategoryExpense(payload));
      }
      if (activeTab === HISTORY_TABS.INCOMES) {
        // @ts-ignore
        dispatch(getIncomeList(payload));
        // @ts-ignore
        dispatch(getCategoryIncome(payload));
      }
      checkChanged({ from: payload.from, to: payload.to });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, searchFilters.search.length]);

  useEffect(() => {
    if (searchFilters.page > 0) {
      if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
        // @ts-ignore
        dispatch(appendHistoryList(payload));
      }
      if (activeTab === HISTORY_TABS.EXPENSES) {
        // @ts-ignore
        dispatch(appendExpenseList(payload));
      }
      if (activeTab === HISTORY_TABS.INCOMES) {
        // @ts-ignore
        dispatch(appendIncomeList(payload));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters.page, activeTab]);

  useEffect(() => {
    if (!showMobileFinancialAnalysisPage) {
      if (!activeCategoryId && searchFilters.categoryIds && !isMobile) {
        setSearchFilters((prev) => ({
          ...prev,
          categoryIds: undefined,
          page: 0,
        }));
        if (activeTab === HISTORY_TABS.EXPENSES) {
          dispatch(
            // @ts-ignore
            getExpenseList({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
          dispatch(
            // @ts-ignore
            getCategoryExpense({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
        }
        if (activeTab === HISTORY_TABS.INCOMES) {
          dispatch(
            // @ts-ignore
            getIncomeList({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
          dispatch(
            // @ts-ignore
            getCategoryIncome({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
        }
      }
      if (activeCategoryId && activeCategoryId !== searchFilters.categoryIds) {
        const activeCategory = widgetData.filter(
          (category) => category.id === activeCategoryId
        )[0].filterCategories;
        setSearchFilters((prev) => ({
          ...prev,
          categoryIds: [...activeCategory],
          page: 0,
        }));
        if (activeTab === HISTORY_TABS.EXPENSES) {
          dispatch(
            // @ts-ignore
            getExpenseList({
              ...payload,
              categoryIds: [...activeCategory],
              page: 0,
            })
          );
        }
        if (activeTab === HISTORY_TABS.INCOMES) {
          dispatch(
            // @ts-ignore
            getIncomeList({
              ...payload,
              categoryIds: [...activeCategory],
              page: 0,
            })
          );
        }
      }
    }
    if (activeCategoryId) {
      setChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategoryId, showMobileFinancialAnalysisPage]);

  useEffect(() => {
    if (!showMobileFinancialAnalysisPage) {
      handleReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!isApiLoading) setLoading(isApiLoading);
  }, [isApiLoading]);

  useEffect(() => {
    return () => {
      dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
      dispatch(
        setDate({
          from: fromDate.toISOString(),
          to: getEndOfDay(dateNow),
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isMobile &&
      activeTab === HISTORY_TABS.ALL_OPERATIONS &&
      !showMobileFinancialAnalysisPage
    ) {
      // @ts-ignore
      dispatch(getCategoryExpense(initialState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMobileFinancialAnalysisPage]);

  return (
    <HistoryWrapper
      isMobile={isMobile}
      isEmptyScreen={!isLoading && isMobile && !data[activeTab].length}
    >
      <HistoryTabs handleDownload={handleDownload} reset={reset} />

      <DashboardWrapper ref={dashboardRef}>
        <Stack
          mb={{
            lg: 12,
            xs: 8,
          }}
          flexDirection="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <HistoryChartTabs
            value={chartTab}
            onChange={onChangeChartTab}
            isMobile={isMobile}
          />

          <OperationDate
            isHideArrows={isLoading}
            fullWidth={false}
            position="right"
            value={{
              from: searchFilters.from,
              to: searchFilters.to,
            }}
            onChange={onChangeFilterValue}
            onSearch={handleSearch}
            isMobile={isMobile}
            filterValues={searchFilters}
            appliedValues={{
              from: searchFilters.from,
              to: searchFilters.to,
            }}
            changeDateArrowHandler={changeDateArrowHandler}
          />
        </Stack>

        <HistoryChart
          isMobile={isMobile}
          isLoading={isHistoryChartDataLoading}
          data={
            !isHistoryChartDataLoading && historyChartData.statements?.length
              ? historyChartData.statements
              : generatePlaceholderData(searchFilters.from, searchFilters.to)
          }
          barWidth={getChartBarWidth(isMobile)}
          barHeight={getChartBarHeight(isMobile)}
          containerRef={dashboardRef}
          incomeSum={historyChartData.statistic?.incomeSum || 0}
          expenseSum={historyChartData.statistic?.expenseSum || 0}
          chartType={getChartTypeByActiveTab()}
        />
      </DashboardWrapper>

      <>
        <SearchFilters
          filterValues={searchFilters}
          cards={[cards.mainCard, ...cards.anotherCards]}
          onFilterChange={onChangeFilterValue}
          onSearch={handleSearch}
          onReset={handleReset}
          onClearAmount={handleClearAmount}
          onClearCard={handleClearCard}
          onChangeCard={handleChangeCard}
          isMobile={isMobile}
          isChanged={isChanged}
          isLoading={isApiLoading}
          isSearchVisible={isSearchVisible}
          setSearchVisible={setSearchVisible}
        />
        {!isMobile && activeTab !== HISTORY_TABS.ALL_OPERATIONS && (
          <FinancialAnalysisWidget isPreview={false} />
        )}

        <SkeletonContainer height="570px" isLoading={isApiLoading} width="100%">
          {!isLoading &&
            (data[activeTab].length ? (
              <InfiniteScroll
                lastBlockRef={lastBlockRef}
                rootSentinelRef={rootSentinelRef}
                isLoading={false}
                onLoadMore={handleLoadMore}
                reverse
                cssVieportHeight="500px"
              >
                {/*@ts-ignore*/}
                {data[activeTab].map((item) => {
                  if (!item.statements.length) return null;
                  return (
                    <Box key={item.date} mb={8}>
                      <DateStyled>
                        {parseTransactionsDate(item.date || "")}
                      </DateStyled>
                      <Box display="flex" flexDirection="column">
                        {/*@ts-ignore*/}
                        {item.statements.map((el) => (
                          <OperationItem
                            key={el.statementId}
                            item={el}
                            onOpenModal={openModal}
                          />
                        ))}
                      </Box>
                    </Box>
                  );
                })}
              </InfiniteScroll>
            ) : isDefaultFilters ? (
              <EmptyScreenDefault />
            ) : (
              <EmptyScreenCustom />
            ))}
          {modalStatus.isOpen && (
            <HistoryModal
              isOpen={modalStatus.isOpen}
              onClose={closeModal}
              historyId={modalStatus.historyId}
            />
          )}
        </SkeletonContainer>
      </>
    </HistoryWrapper>
  );
};

export default History;
