import { Box, FormControlLabel, TextField } from "@mui/material";
import theme from "theme";
import {
  ChangeEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { v4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { chatActions, sendChatMessage } from "store/slices/chat";
import { chatSelector, store } from "store";
import { getBase64 } from "../../utils";
import { acceptedFiles } from "../../../../constants";
import { makeStyles } from "@mui/styles";
import { Loader } from "../Loader";
import { makeRating } from "store/slices/notifications";
import { IconSendMessage } from "atoms/IconSendMessage/IconSendMessage";
import { IconSquarePlus } from "atoms/IconSquarePlus/IconSquarePlus";

export const MAX_FILES = 1;

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .MuiInputBase-root": {
      color: "var(--main-color-text-title)",
      borderRadius: "12px",
      lineHeight: "20px",
      padding: "8px 12px",
      border: "none",
      "&:hover *": {
        border: "none",
      },
      "&:focus-within *": {
        border: "none",
      },
      "&:disabled *": {
        border: "none",
      },
    },
    "& .MuiFormControlLabel-root": {
      margin: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0)",
    },
  },
}));

const textFieldStyle = {
  "& .MuiInputBase-root": {
    background: theme.palette.gray.b50,
    border: `1px solid ${theme.palette.gray.b50} !important`,
    "& textarea": {
      fontSize: 14,
      margin: "0 auto",
      color: theme.primary.main.gray["800"],
      fontWeight: 500,
      padding: "0 8px 0 0",
    },
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-root:focus-within": {
    border: `1px solid ${theme.palette.blue.b400} !important`,
  },
  "& .MuiInputBase-input": {
    overflow: "auto !important",
    maxHeight: 136,
  },
};

type FileInputProps = {
  onChange: (
    event: SyntheticEvent<EventTarget, Event>,
    checked: boolean
  ) => void;
  disabled?: boolean;
  isExtraLine: boolean;
  click?: () => void;
};

const FileInput = ({
  onChange,
  disabled,
  click,
  isExtraLine,
}: FileInputProps) => {
  return (
    <FormControlLabel
      sx={{
        width: 20,
        height: "100%",
        margin: 0,
        "& .MuiTypography-root": { display: "flex", height: "100%" },
      }}
      onChange={onChange}
      control={
        <TextField
          autoComplete="off"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            visibility: "hidden",
          }}
          id="file"
          type="file"
          hidden
          disabled={disabled}
          inputProps={{
            accept: acceptedFiles,
            multiple: true,
          }}
        />
      }
      label={
        <Box
          onClick={click}
          sx={{
            height: "100%",
            display: "flex",
            alignItems: isExtraLine ? "flex-end" : "center",
          }}
        >
          <IconSquarePlus
            width={20}
            height={20}
            color={disabled ? "#E8ECE3" : undefined}
          />
        </Box>
      }
      htmlFor="file"
    />
  );
};

type Props = {
  lastBlockRef: React.RefObject<HTMLDivElement>;
};

const SendMessage = ({ lastBlockRef }: Props) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const {
    files,
    isSizeError,
    isMessageSending,
    showOperatorRating,
    idRatingMessage,
  } = useSelector(chatSelector);
  const [text, setText] = useState("");
  const classes = useStyles();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setText(event.target.value);
  };

  const clearSizeError = () => {
    dispatch(chatActions.clearIsFileWithErrorSize());
    dispatch(chatActions.setIsSizeErrorClosed());
  };

  const handleChangeFile = (event: any) => {
    const selectedFiles = Array.from(event.target.files) as File[];
    const filesToAdd = selectedFiles.slice(0, MAX_FILES - files.length);

    filesToAdd.forEach((file) => {
      dispatch(chatActions.addFile({ id: v4(), file }));
    });

    event.target.value = null;
    setTimeout(() => clearSizeError(), 10000);
  };

  const handleSendText = useCallback(async () => {
    if (!text.trim().length && !files.length) {
      return;
    } else {
      const attachments = files.map(({ file }) => file);

      if (showOperatorRating) {
        dispatch(
          makeRating({
            rating: "0",
            messageId: idRatingMessage,
          })
        );
        dispatch(chatActions.setShowOperatorRating(false));
      }

      dispatch(sendChatMessage({ text, attachments }))
        .unwrap()
        .then((data) => {
          dispatch(chatActions.setMessage(data));
        })
        .catch((err) => {
          if (err.response.status === 406) {
            const msg = JSON.parse(err.response.data.additional);
            dispatch(chatActions.setMessage(msg));
            return;
          }
          dispatch(
            chatActions.setMessage({
              id: v4(),
              text,
              user: undefined,
              createdAt: new Date().toISOString(),
              read: false,
              isError: true,
              // @ts-ignore
              attachments: attachments.map((file) => ({
                mimeType: file.type,
                fileName: file.name,
                url: file.type.includes("image") ? getBase64(file) : undefined,
              })),
            })
          );
        })
        .finally(() => {
          setText("");
          dispatch(chatActions.clearFiles());
          dispatch(chatActions.clearChatMessageCounter());
          lastBlockRef.current?.scrollIntoView({
            block: "end",
          });
          clearSizeError();
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lastBlockRef, text, files]);

  useEffect(() => {
    if (isSizeError) {
      dispatch(chatActions.clearFiles());
    }
  }, [dispatch, isSizeError]);

  const isDisabled = !text.trim().length && !files.length;
  const showSendButton = text.trim().length > 0 || files.length > 0;
  const fileInputDisabled = isMessageSending || files.length >= MAX_FILES;
  const isMoreThanOneLine =
    files.length > 0 || (text ? text.split("\n").length > 1 : false);

  return (
    <Box display="flex" gap="8px" alignItems="center">
      <FileInput
        disabled={fileInputDisabled}
        onChange={handleChangeFile}
        click={clearSizeError}
        isExtraLine={isMoreThanOneLine}
      />
      <TextField
        fullWidth
        multiline
        value={!isMessageSending ? text : ""}
        onChange={handleChange}
        classes={{ root: classes.inputRoot }}
        sx={textFieldStyle}
        placeholder={
          !isMessageSending ? "Сообщение для техподдержки" : undefined
        }
        disabled={isMessageSending}
        InputProps={
          showSendButton
            ? {
                endAdornment: (
                  <Box
                    onClick={!isMessageSending ? handleSendText : void 0}
                    m="auto 0 0 8px"
                    component="div"
                    width={20}
                    height={20}
                    sx={{ cursor: "pointer" }}
                  >
                    {isMessageSending ? (
                      <Loader />
                    ) : (
                      <IconSendMessage
                        color={isDisabled ? "#D9E3CC" : undefined}
                      />
                    )}
                  </Box>
                ),
              }
            : undefined
        }
      />
    </Box>
  );
};

export default SendMessage;
