import { Box, Typography, useMediaQuery } from "@mui/material";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconAccessBiometry } from "atoms/IconAccessBiometry";
import { IconClose } from "atoms/IconClose/IconClose";
import { IconErrorBiometry } from "atoms/IconErrorBiometry";
import { CardModal } from "components/CardModal/CardModal";
import { CardActions } from "components/CardsInfo/CardActions";
import { ErrorFullScreen } from "components/ErrorFullScreen";
import { MainSlider } from "components/MainSlider";
import { sectionTitles } from "constants/sectionTitles";
import { useIsMobile } from "hooks/useIsMobile";
import { Modal } from "molecules/Modal/Modal";
import { NewsInfo } from "organisms/Notifications/NewsInfo";
import Other from "organisms/Other";
import { AccountInfo } from "pages/cards/modal/AccountModal/AccountInfo";
import { CardInfo } from "pages/cards/modal/cardsSlider/CardInfo";
import {
  RootState,
  cardsSelector,
  notificationSelector,
  profileSelector,
  systemSelector,
} from "store";
import {
  CARD_ACTION,
  cardActionsToggle,
  getCardInfo,
  setCardError,
  setIsAccountInfoOpen,
  setIsCardInfoOpen,
} from "store/slices/cardsSlice";
import {
  HISTORY_TABS,
  appendExpenseList,
  appendIncomeList,
  getCategoryExpense,
  getCategoryIncome,
  getExpenseList,
  getIncomeList,
  setActiveCategory,
  setShowMobileFinancialAnalysisPage,
} from "store/slices/historySlice";
import {
  checkNotifications,
  notificationActions,
} from "store/slices/notifications";
import { profileActions } from "store/slices/profile";
import { getUserInfo } from "store/slices/profile/asyncThunks";
import {
  MobileTabVariants,
  TabVariants,
  systemActions,
} from "store/slices/system";
import { AnotherPages } from "store/slices/system/slice";
import { TRANSFER_ERROR_CODE } from "store/slices/transferSlice";
import { AccountsAndCards, ChatWidget } from "widgets";
import { Profile } from "../profile";
import { Cards } from "./Cards";
import { FinancialAnalysisMobilePage } from "./Cards/History/components/FinancialAnalysisWidget/FinancialAnalysisMobilePage";
import { MainGrid, MainGridItem } from "components/Grid";
import styles from "./styles.module.css";
import { NotificationNewsModal } from "pages/profile/components";
import ExchangeRatesScreen from "organisms/Other/components/ExchangeRatesScreen";
import Contacts from "organisms/Other/components/Contacts";
import ActivityMonitor from "components/ActivityMonitor/ActivityMonitor";
import ChatScreen from "organisms/ChatScreen/ChatScreen";

const Home = () => {
  const [modaliIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { isModalOpened, user, isBiometryConnected } =
    useSelector(profileSelector);
  const { selected } = useSelector(notificationSelector);
  const {
    isCardInfoOpen,
    isAccountInfoOpen,
    isCardActionsOpen,
    chosenCardIndex,
    allCards,
    errorMessage: cardsErrMsg,
    screen,
  } = useSelector(cardsSelector);
  const card = allCards[chosenCardIndex || 0];
  const lastFourNumber = card?.cardPan?.slice(-4);
  const postFix = (
    <span style={{ color: "var(--main-color-notification-description)" }}>
      ● {lastFourNumber}
    </span>
  );

  const getModalTitle = () => {
    switch (screen) {
      case CARD_ACTION.LIMIT:
        return <>Лимиты по карте {postFix}</>;
      case CARD_ACTION.LOST:
        return <>Карта {postFix}</>;
      default:
        return "Детали карты";
    }
  };

  const modalTitle = getModalTitle();

  const pushType = localStorage.getItem("pushType");

  const {
    transfer: { errorMessage },
    history,
    cards,
  } = useSelector((state: RootState) => state);

  const {
    date,
    showMobileFinancialAnalysisPage,
    activeCategoryId,
    widgetData,
    historyList,
    expenseList,
    incomeList,
  } = history;

  const data = {
    [HISTORY_TABS.ALL_OPERATIONS]: historyList,
    [HISTORY_TABS.EXPENSES]: expenseList,
    [HISTORY_TABS.INCOMES]: incomeList,
  };

  const payload = {
    from: date.from,
    to: date.to,
    operationFilterType: undefined,
    page: 0,
    acctId: cards.account.accountNumber,
    search: "",
    fromAmount: undefined,
    toAmount: undefined,
    cardId: "",
    size: 10,
    categoryIds: undefined,
  };

  const [initialState, setInitialState] = useState(payload);

  const { activeTab, chatIsOpen, isNewAndNotificationsOpen } =
    useSelector(systemSelector);

  const { isMobile } = useIsMobile();

  const reset = () => {
    setInitialState((prev) => ({ ...prev, page: 0 }));
  };

  const handleProfileClose = useCallback(() => {
    dispatch(profileActions.setIsModalOpened(false));
  }, [dispatch]);

  const handleCardModalClose = () => {
    if (cardsErrMsg.code === "CHANGE_CARD_STATUS_RESPONSE_ERROR_CODE") {
      const cardId = allCards[chosenCardIndex || 0].cardId;
      dispatch(setCardError({}));
      dispatch(cardActionsToggle());
      if (cardId) dispatch(getCardInfo({ cardId }));
    }

    dispatch(setIsCardInfoOpen(0));
  };

  const onToggleCardActions = () => {
    if (!isCardActionsOpen) {
      dispatch(setIsCardInfoOpen(0));
    } else {
      dispatch(cardActionsToggle());
    }
  };

  const handleAccountModalClose = () => {
    dispatch(setIsAccountInfoOpen(0));
  };

  useEffect(() => {
    setInitialState(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards.account.accountNumber, date.from, date.to]);

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(checkNotifications());
  }, [dispatch]);

  useEffect(() => {
    dispatch(systemActions.setInitialTab({ isMobile }));
  }, [dispatch, isMobile]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(profileActions.setBiometryConnected(""));
    }, 5000);
  }, [dispatch, isBiometryConnected]);

  const onBackNews = () => dispatch(notificationActions.clearSelected());

  const onCloseNewAndNotifications = () => {
    dispatch(systemActions.setNewAndNotificationsOpen(false));
  };

  const onCloseChat = () => {
    if (isMobile) {
      dispatch(systemActions.setBack());
    } else {
      dispatch(systemActions.setChatOpen(false));
    }
  };

  const showTransferError =
    activeTab === MobileTabVariants.transfersHub &&
    errorMessage.title.length &&
    errorMessage.code !== TRANSFER_ERROR_CODE.CARD &&
    errorMessage.code !== TRANSFER_ERROR_CODE.PAYMENT_ERROR &&
    errorMessage.code !== TRANSFER_ERROR_CODE.PAYMENT_RESPONSE_ERROR &&
    errorMessage.code !== TRANSFER_ERROR_CODE.MESSAGE;

  const isDesktop = useMediaQuery("(min-width:980px)");

  const displayCardsAndAccountsBlock =
    activeTab === MobileTabVariants.main ||
    (isDesktop && activeTab !== AnotherPages.promotion);

  const displayOtherBlock = activeTab === TabVariants.other;
  const displayChatBlock = activeTab === MobileTabVariants.chat;
  const displayContactsBlock = activeTab === AnotherPages.contacts;
  const displayExchangeRatesBlock = activeTab === AnotherPages.exchangeRates;
  const displayTransfersBlock = activeTab !== AnotherPages.transfers;

  const handleLoadMore = () => {
    const len = data[history.activeTab].reduce(
      // @ts-ignore
      (acc, it) => it.statements.length + acc,
      0
    );
    const isEndOfSlice = len % 10 === 0;
    const isSamePage = Math.floor(len / 10) === initialState.page;

    const body = {
      ...initialState,
      page: Math.floor(len / 10),
    };

    if (!isSamePage && isEndOfSlice) {
      if (history.activeTab === HISTORY_TABS.EXPENSES) {
        dispatch(appendExpenseList(body));
      }
      if (history.activeTab === HISTORY_TABS.INCOMES) {
        dispatch(appendIncomeList(body));
      }
      setInitialState(body);
    }
  };

  useEffect(() => {
    if (
      initialState.acctId &&
      history.activeTab === HISTORY_TABS.EXPENSES &&
      showMobileFinancialAnalysisPage
    ) {
      dispatch(getExpenseList(initialState));
      dispatch(getCategoryExpense(initialState));
    }
    if (
      initialState.acctId &&
      history.activeTab === HISTORY_TABS.INCOMES &&
      showMobileFinancialAnalysisPage
    ) {
      dispatch(getIncomeList(initialState));
      dispatch(getCategoryIncome(initialState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialState.from,
    initialState.to,
    showMobileFinancialAnalysisPage,
    history.activeTab,
  ]);

  useEffect(() => {
    if (
      !activeCategoryId &&
      initialState.categoryIds &&
      showMobileFinancialAnalysisPage
    ) {
      setInitialState((prev) => ({
        ...prev,
        categoryIds: undefined,
        page: 0,
      }));
      if (history.activeTab === HISTORY_TABS.EXPENSES) {
        dispatch(
          getExpenseList({
            ...payload,
            categoryIds: undefined,
            page: 0,
          })
        );
        dispatch(
          getCategoryExpense({
            ...payload,
            categoryIds: undefined,
            page: 0,
          })
        );
      }
      if (history.activeTab === HISTORY_TABS.INCOMES) {
        dispatch(
          getIncomeList({
            ...payload,
            categoryIds: undefined,
            page: 0,
          })
        );
        dispatch(
          getCategoryIncome({
            ...payload,
            categoryIds: undefined,
            page: 0,
          })
        );
      }
    }
    if (
      activeCategoryId &&
      activeCategoryId !== initialState.categoryIds &&
      showMobileFinancialAnalysisPage
    ) {
      const activeCategory = widgetData?.filter(
        (category) => category.id === activeCategoryId
      )[0].filterCategories;
      // @ts-ignore
      setInitialState((prev) => ({
        ...prev,
        categoryIds: [...(activeCategory || [])],
        page: 0,
      }));
      if (history.activeTab === HISTORY_TABS.EXPENSES) {
        dispatch(
          getExpenseList({
            ...payload,
            categoryIds: [...(activeCategory || [])],
            page: 0,
          })
        );
      }
      if (history.activeTab === HISTORY_TABS.INCOMES) {
        dispatch(
          getIncomeList({
            ...payload,
            categoryIds: [...(activeCategory || [])],
            page: 0,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategoryId, showMobileFinancialAnalysisPage]);

  useEffect(() => {
    if (pushType === "MESSAGE" && activeTab !== MobileTabVariants.chat) {
      setTimeout(() => {
        dispatch(systemActions.setActiveTab({ tab: MobileTabVariants.chat }));
        localStorage.removeItem("pushType");
      }, 500);
    }
  }, [activeTab, dispatch, pushType]);

  return (
    <div>
      <MainGrid
        main
        container
        paddings={{
          xs: displayChatBlock ? "40 0 0" : "30 16 140",
          md: displayChatBlock ? "40 0 0" : "40 20 140",
          l: displayChatBlock ? "40 0 0" : "40 0 140",
        }}
      >
        {sectionTitles?.[activeTab]?.desktop &&
          sectionTitles?.[activeTab]?.mobile &&
          !!showTransferError && (
            <MainGridItem columns={{ xs: [1, -1] }}>
              <Box p={{ xs: "24px 0 28px 0", xl: "40px 0 40px 0" }}>
                {showTransferError ? (
                  <ErrorFullScreen
                    title={errorMessage.title}
                    description={errorMessage.subtitle}
                  />
                ) : (
                  ""
                )}
              </Box>
            </MainGridItem>
          )}
        {(isBiometryConnected === "connected" ||
          isBiometryConnected === "error") && (
          <Box
            sx={{
              position: "fixed",
              top: 20,
              left: 20,
              right: 20,
              padding: 16,
              backgroundColor:
                isBiometryConnected === "connected" ? "#ECFDF3" : "#FDE7D4",
              borderRadius: 16,
              display: "flex",
              alignItems: "flex-start",
              gap: 4,
              zIndex: 1000,
              "-webkit-box-shadow": "2px 8px 20px 0px rgba(34, 60, 80, 0.37)",
              "-moz-box-shadow": "2px 8px 20px 0px rgba(34, 60, 80, 0.37)",
              boxShadow: "2px 8px 20px 0px rgba(34, 60, 80, 0.37)",
            }}
          >
            {isBiometryConnected === "connected" ? (
              <IconAccessBiometry />
            ) : (
              <IconErrorBiometry />
            )}
            <Typography
              variant="text_3"
              color="var(--main-color-text-secondary)"
              width="90%"
              fontWeight="400"
            >
              {isBiometryConnected === "connected" ? (
                <p className={styles.title} style={{ color: "#33B438" }}>
                  Вход по Face и Touch ID
                  <br />
                  успешно подключён
                </p>
              ) : (
                <p className={styles.title} style={{ color: "#E57616" }}>
                  При подключении входа по
                  <br />
                  Face и Touch ID возникла
                  <br />
                  ошибка
                </p>
              )}
            </Typography>

            <IconClose
              color={
                isBiometryConnected === "connected" ? "#33B438" : "#E57616"
              }
              onClick={() => {
                dispatch(profileActions.setBiometryConnected(""));
              }}
            />
          </Box>
        )}
        {displayOtherBlock && <Other />}
        {displayChatBlock && <ChatScreen />}
        {displayContactsBlock && <Contacts />}
        {displayExchangeRatesBlock && <ExchangeRatesScreen />}

        {!displayOtherBlock &&
          !displayChatBlock &&
          !displayContactsBlock &&
          !displayExchangeRatesBlock &&
          displayCardsAndAccountsBlock &&
          displayTransfersBlock && (
            <>
              <MainGridItem columns={{ xs: [1, -1], l: [1, 4] }}>
                <AccountsAndCards />
              </MainGridItem>
            </>
          )}

        <MainGridItem
          columns={{
            xs: [1, -1],
            l:
              activeTab === AnotherPages.transfers
                ? [1, 6]
                : activeTab === AnotherPages.promotion
                ? [1, -1]
                : [5, -1],
          }}
        >
          {activeTab === TabVariants.main && <MainSlider />}
          <Typography
            variant="text_14"
            color="var(--main-color-text-title)"
            sx={{
              fontWeight: 500,
              fontSize: `${isMobile ? 28 : 32} !important`,
              lineHeight: "40px",

              "@media (max-width: 600px)": {
                fontSize: "24px !important",
                lineHeight: "28px",
              },
            }}
          >
            {isMobile
              ? sectionTitles?.[activeTab]?.mobile
              : sectionTitles?.[activeTab]?.desktop}
          </Typography>
          <Cards />
        </MainGridItem>
        <FinancialAnalysisMobilePage
          isOpen={showMobileFinancialAnalysisPage}
          onClose={() => {
            dispatch(setShowMobileFinancialAnalysisPage(false));
            dispatch(setActiveCategory(null));
          }}
          loadMore={handleLoadMore}
          reset={reset}
        />
        <Modal
          title={modalTitle}
          isOpen={isCardInfoOpen}
          onClose={handleCardModalClose}
          buttonVisible={true}
          callback={onToggleCardActions}
          showTitle={false}
          isProfile
        >
          {isCardActionsOpen ? (
            <CardActions />
          ) : (
            <CardInfo handleMenuClick={() => setModalIsOpen(true)} />
          )}
        </Modal>
        <Modal
          title={
            <Typography fontSize="inherit" display="contents">
              Изменение <br />
              названия карты
            </Typography>
          }
          subTitle={card?.cardPan?.substring(card?.cardPan.length - 4)}
          isOpen={modaliIsOpen}
          onClose={() => {
            setModalIsOpen(false);
          }}
          buttonVisible={true}
          callback={() => {
            setModalIsOpen(false);
          }}
          isProfile
          headerTop
          titleMarginTop="-5px"
        >
          <CardModal
            name={card?.description}
            cardId={card?.cardId || ""}
            onClose={() => {
              setModalIsOpen(false);
            }}
          />
        </Modal>
        <Modal
          title={cards?.account.description}
          isOpen={isAccountInfoOpen}
          onClose={handleAccountModalClose}
          buttonVisible={true}
          callback={handleAccountModalClose}
          isProfile
        >
          <AccountInfo />
        </Modal>
        <Modal
          title="Профиль"
          isOpen={isModalOpened}
          onClose={handleProfileClose}
          buttonVisible={isMobile}
          callback={handleProfileClose}
          isProfile
        >
          <Profile phoneNumber={user?.phone as string} />
        </Modal>
        <Modal
          title="Чат с оператором"
          isOpen={chatIsOpen}
          onClose={onCloseChat}
          buttonVisible
          callback={onCloseChat}
          isProfile
          isCenteredTitle
        >
          <ChatWidget />
        </Modal>

        <Modal
          title="Уведомления"
          isOpen={isNewAndNotificationsOpen}
          onClose={onCloseNewAndNotifications}
          buttonVisible={isMobile}
          callback={onCloseNewAndNotifications}
          isProfile
        >
          <NotificationNewsModal />
        </Modal>

        <Modal
          title=""
          isOpen={!isEmpty(selected)}
          onClose={onBackNews}
          buttonVisible
          isProfile
          callback={onBackNews}
        >
          <NewsInfo {...selected} />
        </Modal>
        <ActivityMonitor />
      </MainGrid>
    </div>
  );
};

export default Home;
