import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { NotificationResult } from "molecules/NotificationResult";

import { PIN_LENGTH } from "organisms/PinCode";
import { CodeInput } from "components/CodeInput";
import { authActions, updatePinCode, verifyPinCode } from "store/slices/auth";
import { authSelector } from "store";
import { useAuthCommonActions } from "hooks";
import PinInput from "react-pin-input";
import { IconLoginChangeSuccess } from "atoms/IconLoginChangeSuccess/IconLoginChangeSuccess";
import { Button } from "components/Button";

export type PinCodeUpdateType = {
  value1: string;
  value2: string;
  value3: string;
};

type UpdatePinCodeProps = {
  onClose: () => void;
};

const initialValues = {
  value1: "",
  value2: "",
  value3: "",
};

const UpdatePinCode = ({ onClose }: UpdatePinCodeProps) => {
  const { isLoading } = useSelector(authSelector);
  const { handleLogout } = useAuthCommonActions();
  const dispatch = useDispatch();

  const [pinCode, setPinCode] = useState<PinCodeUpdateType>(initialValues);
  const [errorAnswer, setErrorAnswer] = useState("");
  const [pinCreate, setPinIsCreate] = useState(false);
  const [verified, setVerified] = useState(false);
  const [verifyPin, setVerifyPin] = useState("");
  const [value, setValue] = useState("");

  const { value1, value2, value3 } = pinCode;
  let ele = useRef<PinInput | null>(null);

  const isInitialPin = String(value1).length < PIN_LENGTH;
  const isPinComplete =
    value1.length === PIN_LENGTH &&
    value2.length === PIN_LENGTH &&
    value3.length === PIN_LENGTH;

  const handleChangeWeb = (code?: string) => {
    const currentStep = isInitialPin ? 1 : value2.length < PIN_LENGTH ? 2 : 3;
    setErrorAnswer("");
    if (code?.length === PIN_LENGTH) {
      setPinCode({
        ...pinCode,
        [`value${currentStep}`]: code
          ? `${pinCode[`value${currentStep}`]}${code}`
          : pinCode[`value${currentStep}`].slice(0, -1),
      });
      //@ts-ignore
      ele?.current?.clear();
      setValue("");
    }
  };

  useEffect(() => {
    if (value1.length === PIN_LENGTH && !value2 && !value3 && !verified) {
      dispatch(verifyPinCode(value1))
        //@ts-ignore
        .unwrap()
        .then((res) => {
          setVerifyPin(res.verifyPinToken);
          setVerified(true);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            dispatch(authActions.setLoginIsBlocked(true));
            dispatch(authActions.setIsPinAllowed(false));
            handleLogout();
          } else {
            setPinCode(initialValues);
            setErrorAnswer(
              `Неверный код, осталось попыток: ${err.response.data.parameter}`
            );
          }
        });
    }
    if (isPinComplete) {
      if (value2 === value3 && verifyPin) {
        dispatch(
          updatePinCode({
            verifyPinToken: verifyPin,
            newPinCode: value2,
            repeatNewPinCode: value3,
          })
        )
          //@ts-ignore
          .unwrap()
          .then(() => {
            setPinIsCreate(true);
            dispatch(authActions.setIsPinUpdated(true));
          })
          .catch((err) => {
            setErrorAnswer(err.response.data.title);
            setPinCode({
              ...pinCode,
              value1: pinCode.value1,
              value2: "",
              value3: "",
            });
          });
      } else {
        setErrorAnswer("Коды не совпадают");
        setPinCode({
          ...pinCode,
          value1: pinCode.value1,
          value2: "",
          value3: "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinCode]);

  const setRef = (ref: PinInput | null) => {
    ele.current = ref;
  };

  if (pinCreate) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        marginTop={12}
        flexDirection="column"
        justifyContent="space-between"
        textAlign="center"
      >
        <NotificationResult
          title={"Короткий код\nдля входа изменён"}
          icon={<IconLoginChangeSuccess />}
          height="100%"
          buttonStyle={{ margin: "30px 0 0" }}
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              onClick: onClose,
            },
          ]}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%" }} px={{ xs: 16, xl: 32 }}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        textAlign="center"
        alignItems="center"
      >
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              px: 32,
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              color="var(--main-color-text-title)"
              fontSize={16}
              lineHeight="20px"
              fontWeight={500}
              marginBottom={12}
            >
              {isInitialPin || !verified
                ? "Введите старый код"
                : value2.length < PIN_LENGTH
                ? "Придумайте новый код для входа\nиз 4-х цифр"
                : "Повторите новый код"}
            </Typography>
            <CodeInput
              length={4}
              setRef={setRef}
              secret
              secretDelay={100}
              setCode={setValue}
              error={Boolean(errorAnswer)}
              clearError={() => setErrorAnswer("")}
              disabled={isLoading}
            />

            {errorAnswer && (
              <Typography variant="text_5" color="var(--error-color-primary)">
                {errorAnswer}
              </Typography>
            )}
            <Button
              disabled={value.length !== 4}
              onClick={() => {
                handleChangeWeb(value);
              }}
              variant="primary"
              style={{ marginTop: "32px", marginBottom: "8px" }}
              isLoading={isLoading}
              title="Продолжить"
            />
          </Box>
        </>
      </Box>
    </Box>
  );
};

export default UpdatePinCode;
