import { StatementHistoryElement } from "api/account";
import moment from "moment";
import {
  BAR_HEIGHT,
  BAR_WIDTH,
  MOBILE_BAR_HEIGHT,
  MOBILE_BAR_WIDTH,
} from "./HistoryChart.consts";

export const getExpenseSum = (sum: number, currency = "₽") => {
  const posSum = sum < 0 ? sum * -1 : sum;

  return `- ${posSum} ${currency}`;
};

export const getIncomeSum = (sum: number, currency = "₽") => {
  return `+ ${sum} ${currency}`;
};

export const shortId = () => (2100000000).toString(36);

export const generatePlaceholderData = (from: string, to: string) => {
  const fromDate = moment(from);
  const toDate = moment(to);
  const diff = toDate.diff(fromDate, "days");
  const range: StatementHistoryElement[] = [];

  for (let i = 0; i <= diff; i++) {
    range.push({
      date: moment(from).add(i, "day").format("YYYY-MM-DD"),
      incomeValue: 0,
      expenseValue: 0,
      remainValue: 100,
    });
  }

  return range;
};

export const getChartBarWidth = (isMobile?: boolean) => {
  if (isMobile) return MOBILE_BAR_WIDTH;

  return BAR_WIDTH;
};

export const getChartBarHeight = (isMobile?: boolean) => {
  if (isMobile) return MOBILE_BAR_HEIGHT;

  return BAR_HEIGHT;
};
