import {
  Container,
  Tab,
  TabText,
  TabWrapper,
  DownloadIconWrapper,
} from "./components";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useIsMobile } from "hooks/useIsMobile";
import { IconDownload } from "atoms/IconDownload/IconDownload";

const TABS = Object.values(HISTORY_TABS);
const TABS_MOBILE = [TABS[0], TABS[1], TABS[2]];

interface HistoryTabsProps {
  marginTop?: boolean;
  handleDownload?: () => void;
  reset: () => void;
}

export const HistoryTabs = ({
  marginTop,
  handleDownload,
  reset,
}: HistoryTabsProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const { activeTab, isReportLoading } = useSelector(
    (state: RootState) => state.history
  );
  const tabs = isMobile ? TABS_MOBILE : TABS;

  const download = () => {
    if (handleDownload) handleDownload();
  };

  return (
    <Container marginTop={marginTop}>
      <TabWrapper>
        {tabs.map((tab) => (
          <Tab
            key={tab}
            active={activeTab === tab}
            onClick={() => {
              if (activeTab === tab) return;
              reset && reset();
              dispatch(setActiveTab(tab));
            }}
            width={isMobile ? "50%" : "fit-content"}
            isMobile={isMobile}
          >
            <TabText active={activeTab === tab}>{tab}</TabText>
          </Tab>
        ))}
      </TabWrapper>

      <DownloadIconWrapper
        disabled={isReportLoading}
        onClick={download}
        isMobile={isMobile}
      >
        <IconDownload width={20} height={20} />
      </DownloadIconWrapper>
    </Container>
  );
};
